
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































.about {
  ::v-deep {
    .duo-vertical {
      margin-bottom: 8rem;
    }
  }
}
