
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































.timeline {
  @include fluid(
    margin-top,
    (
      s: 100px,
      xl: 120px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 120px,
    )
  );

  position: relative;
}

.timeline__title {
  @include fluid(
    margin-bottom,
    (
      s: 60px,
      xl: 100px,
    )
  );

  text-align: center;

  ::v-deep {
    b,
    strong {
      @extend %fw-light;
    }
  }
}

.timeline__content {
  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-inline: col(1, 24);
  }
}

.timeline__card {
  @include fluid(
    padding-bottom,
    (
      s: 50px,
      xl: 150px,
    )
  );

  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $c-black-50;

  @include mq($until: m) {
    padding-left: col(2, 20);
  }

  @include mq(m) {
    width: col(10, 20);
    border-left: 0;

    &:nth-child(odd) {
      padding-right: col(1, 20);
      border-right: 1px solid $c-black-50;
    }

    &:nth-child(even) {
      padding-left: col(1, 20);
      transform: translateY(15rem);
    }
  }
}

.timeline__card__date {
  @extend %ff-alt;
  @extend %fw-normal;

  margin-top: -0.3rem;
  margin-bottom: 4.5rem;
  color: $c-black-60;
  font-size: 1.3rem;
  line-height: 2.6rem;
  letter-spacing: 0.5px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2rem;
    height: 2rem;
    background-color: $c-yellow;
    border: 0.6rem solid $c-light-yellow;
    border-radius: 999em;
    transform: translateX(-50%);
  }

  @include mq(m) {
    .timeline__card:nth-child(odd) & {
      margin-left: auto;

      &::after {
        right: 0;
        left: auto;
        transform: translateX(50%);
      }
    }
  }
}

.timeline__card__title {
  @extend %fw-medium;

  margin-bottom: 2.2rem;
  color: $c-dark-blue;
  font-size: 2.4rem;
  line-height: 3.6rem;
  letter-spacing: 0.5px;
}

.timeline__card__text {
  margin-bottom: 4rem;
  color: $c-content;
}

.timeline__card__picture {
  .timeline__card__picture-outer {
    @include aspect-ratio(535, 340);
  }

  img {
    @include get-all-space;

    object-fit: cover;
    border-radius: 4rem;
  }
}

.timeline__action {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}
